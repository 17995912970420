<template>
  <div id="navImpersonator" class="w-100 flex-grow-1">
    <div class="h-100 py-4 container">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary">
            <div class="card-body p-5">
              <h1 class="h2 mb-5 font-weight-bold text-center d-flex flex-column align-items-center justify-content-center">
                <Logo :fill="'#212529'" :height="'40px'" :width="'40px'" class="mb-2 mr-2 d-flex align-items-center">
                </Logo>
                Adobe Renewal Manager
              </h1>
              <div v-if="error" class="alert alert-danger">{{ error }}</div>
              <form @submit.prevent="submit" class="needs-validation" novalidate>
                <div class="mb-4 form-group d-flex flex-column">
                  <label for="email">E-Mail-Adresse</label>
                  <input id="email" type="email" class="form-control" name="email" required autofocus v-model="form.email" />
                </div>
                <div class="mb-4 form-group d-flex flex-column">
                  <label for="password" >Passwort</label>
                  <input id="password" type="password" class="form-control" name="password" required
                    v-model="form.password" />
                </div>
                <button class="w-100 btn btn-primary mt-2" type="submit"
                  :disabled="form.email === '' || form.password === ''">Anmelden</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth"
import { getAuth } from "firebase/auth";
import Logo from "../assets/Logo.vue"
export default {
  components: {
    Logo,
  },
  name: 'Login',
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null
    };
  },
  methods: {
        submit() {
          signInWithEmailAndPassword(this.$user, this.form.email, this.form.password)
            .catch(err => {
              if (err.code === 'auth/wrong-password') this.error = 'Das Passwort ist ungültig.'
              else if (err.code === 'auth/user-not-found') this.error = 'Es existiert kein Benutzerdatensatz, der dieser Kennung entspricht.'
              else if (err.code === 'auth/user-disabled') this.error = 'Das Benutzerkonto wurde von einem Administrator deaktiviert.'
              else this.error = err.message
        });
    },
    checkLogState(){
      if (this.$user.currentUser) {
        this.$router.replace({ name: "home" });
      }
    }
  },
  mounted(){
    this.checkLogState()
    let token = this.$route.params.token || 0
    signInWithCustomToken(getAuth(), token)
    .then(()=>{
      this.$router.push({name: 'home'})
    })
    .catch(err=>{console.error(err)})
  }

};
</script>

<style scoped>

input {
  background-color: white !important;
}

.border-top {
  border-top-width: 5px !important;
}

#navImpersonator {
  margin-top: 37px
}
</style>