<template v-if="this.user">
  <b-container>
    <div v-if="!state">
      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        placeholder="Eine Datei auswählen oder ablegen..."
        drop-placeholder="Datei hier reinziehen..."
      ></b-form-file>
      <b-button @click="upload()" class="mt-2" v-if="file">Hochladen</b-button>
    </div>
    <b-progress class="mt-2" :max="100" show-value v-if="state">
      <b-progress-bar
        :value="value"
        :variant="progressState()"
      ></b-progress-bar>
    </b-progress>
  </b-container>
</template>

<script>
import { onValue, ref, update } from "firebase/database";
import { httpsCallable } from "firebase/functions";
export default {
  name: "Upload",
  components: {},
  data() {
    return {
      file: undefined,
      fileReader: new FileReader(),
      uploadState: "running",
      value: 0,
      state: undefined,
    };
  },
  methods: {
    upload() {
      this.state = "started";
      this.fileReader.onload = (e) => {
        var uploadExcel = httpsCallable(this.$func, "uploadExcel");
        uploadExcel(e.target.result).then((result) => {
          console.log(result);
        });
      };
      this.fileReader.readAsBinaryString(this.file);
    },
    readFromDb() {
      onValue(ref(this.$rdb), (snapshot) => {
        let data = snapshot.val();
        this.value = data.upload.progress;
        this.uploadState = data.upload.state;
      });
    },
    progressState() {
      if (this.uploadState === "running") {
        return "primary";
      } else if (this.uploadState === "finnished") {
        return "success";
      } else {
        return "danger";
      }
    },
    resetProgress() {
      update(ref(this.$rdb), {
        progress: 0,
        state: "running",
      });
    },
  },
  mounted() {
    this.resetProgress();
    this.readFromDb();
  },
};
</script>

<style scoped>
</style>
