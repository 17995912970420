<template>
  <b-card id="card" class="m-2 p-2">
    <b-row id="search-row">
      <b-col id="first-col">
        <b-row>
          <b-col
            cols="8"
            style="display: flex; flex-wrap: wrap; align-items: center"
          >
            CCD:
            <b-form-datepicker
              class="ml-2 mr-2"
              v-if="data.ccd"
              :value="stampToDate(data.ccd.from)"
              size="sm"
              button-content="false"
              locale="de"
              start-weekday="Monday"
              style="width: 120px"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              @context="onChangeFrom"
            ></b-form-datepicker>
            -
            <b-form-datepicker
              class="ml-2"
              v-if="data.ccd"
              :value="stampToDate(data.ccd.to)"
              size="sm"
              button-content="false"
              locale="de"
              start-weekday="Monday"
              style="width: 120px"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              @context="onChangeTo"
            ></b-form-datepicker>
            <template v-if="data.ccd !== null">
              <template
                v-if="data.ccd.from !== ccdFrom || data.ccd.to !== ccdTo"
              >
                <span
                  class="ml-2 clickable"
                  v-b-tooltip.hover
                  title="Für alle speichern"
                  @click.prevent="saveDates()"
                  ><font-awesome-icon :icon="['fa', 'save']"
                /></span>
              </template>
            </template>
          </b-col>
          <b-col>
            <span
              class="clickable"
              v-on:click="
                selectedSearchFilter = 'state';
                $emit('searchInput', 'voll');
                $refs.search.$el.value = 'voll';
              "
              style="min-width: 100px"
            >
              Orders:
              <b-spinner small v-if="orders(data.ARMData) === null" /><span
                v-else
                v-b-tooltip.hover
                title="Erledigt / Alle"
                >{{ orders(data.ARMData) }}</span
              >
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            Renewals: <b-spinner small v-if="renewals(data) === null" /><span
              v-else
              >{{ renewals(data) }}</span
            >
          </b-col>
          <b-col>
            <span
              class="clickable"
              v-on:click="
                selectedSearchFilter = 'state';
                $emit('searchInput', 'error');
                $refs.search.$el.value = 'error';
              "
              style="min-width: 100px"
            >
              Errors:
              <b-spinner small v-if="errors(data.ARMData) === null" /><span
                v-else
                >{{ errors(data.ARMData) }}</span
              >
            </span>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="8"
        class="d-flex justify-content-start"
        style="align-items: center"
        id="last-col"
      >
        <b-form-select
          v-model="selectedSearchStatus"
          :options="optionsStatus"
          :change="this.$emit('selectedSearchStatus', selectedSearchStatus)"
          class="mx-5 w-40"
        ></b-form-select>
          <b-input
            placeholder="Suchen"
            type="search"
            @input="SearchClear"
            @keyup.enter.native="Search()"
            ref="search"
          ></b-input>
          <b-btn class="ml-2" v-on:click="Search()">Suchen</b-btn>
        <b-form-select
          v-model="selectedSearchFilter"
          :options="optionsFilter"
          :change="this.$emit('selectedSearchFilter', selectedSearchFilter)"
          class="mx-5 w-40"
        ></b-form-select>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import moment from "moment-timezone";
import { ref, set } from "firebase/database";

export default {
  name: "SearchAndFilter",
  props: {
    data: Object,
  },
  data() {
    return {
      selectedSearchFilter: "",
      selectedSearchStatus: "",
      optionsFilter: [
        {
          value: "",
          text: "Alle Spalten",
        },
        {
          value: "vip",
          text: "VIP Nummer",
        },
        {
          value: "customer",
          text: "Endkunde",
        },
        {
          value: "ced",
          text: "CED",
        },
        {
          value: "ccd",
          text: "CCD",
        },
        {
          value: "reseller",
          text: "Reseller",
        },
        {
          value: "term",
          text: "Term",
        },
        {
          value: "lvl",
          text: "Level",
        },
        {
          value: "productPlan",
          text: "Produkt Plan",
        },
        {
          value: "qtyTD",
          text: "Menge TD",
        },
        {
          value: "partNumber",
          text: "Part Nummer",
        },
        {
          value: "segment",
          text: "Segment",
        },
        {
          value: "qtyA",
          text: "Menge A",
        },
        {
          value: "qtyM",
          text: "Menge M",
        },
        {
          value: "qtySumm",
          text: "Menge Σ",
        },
        {
          value: "state",
          text: "Status",
        },
        {
          value: "comment",
          text: "Bemerkung",
        },
      ],
      optionsStatus: [
        {
          value: "",
          text: "Alle Status",
        },
        {
          value: "voll",
          text: "voll",
        },
        {
          value: "error",
          text: "error",
        },
        {
          value: "teil",
          text: "teil",
        },
        {
          value: "offen",
          text: "offen",
        },
      ],
      ccdFrom: null,
      ccdTo: null,
    };
  },
  methods: {
    renewals(value) {
      if (value.ARMData) {
        if (
          Object.keys(value.ARMData).length !== 0 &&
          value.ARMData !== undefined &&
          value.ARMData !== null
        ) {
          let one = Object.values(value.ARMData).reduce((acc, curr) => {
            return (acc =
              parseInt(acc) + parseInt(curr.qtyM) + parseInt(curr.qtyA));
          }, 0);

          let two = Object.values(value.ARMData).reduce((acc, curr) => {
            return (acc = parseInt(acc) + parseInt(curr.qtyTD));
          }, 0);

          return one + " / " + two;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    errors(value) {
      if (value !== {} && value !== undefined && value !== null) {
        let counted = Object.values(value).filter(
          (item) => item.state === "error"
        ).length;
        if (Object.values(value) <= 0) {
          return null;
        } else {
          return counted;
        }
      } else {
        return null;
      }
    },
    stampToDate(stamp) {
      let date = moment.unix(stamp).format("YYYY-MM-DD");
      return date;
    },
    orders(data) {
      if (data !== {} && data !== undefined && data !== null) {
        let countedSolved = Object.values(data).filter(
          (item) => item.state === "voll"
        ).length;
        let all = Object.values(data).length;
        if (all <= 0) {
          return null;
        } else {
          return countedSolved + " / " + all;
        }
      } else {
        return null;
      }
    },
    onChangeFrom(val) {
      this.ccdFrom = moment(val.activeYMD).tz("Europe/Berlin").format("X");
      if (val.activeYMD === val.selectedYMD) {
        this.$emit("ccdFrom", val.selectedYMD);
      }
    },
    onChangeTo(val) {
      this.ccdTo = moment(val.activeYMD).tz("Europe/Berlin").format("X");
      if (val.activeYMD === val.selectedYMD) {
        this.$emit("ccdTo", val.selectedYMD);
      }
    },
    saveDates() {
      set(ref(this.$rdb, "ccd/"), {
        from: this.ccdFrom,
        to: this.ccdTo,
      }).then(() => {
        this.data.ccd.from = this.ccdFrom;
        this.data.ccd.to = this.ccdTo;
      });
    },
    Search() {
      this.$emit("searchInput", this.$refs.search.$el.value);
    },
    SearchClear() {
      if (this.$refs.search.$el.value === "") {
        this.$emit("searchInput", "");
      }
    },
  },
};
</script>

<style scoped>
/*@media screen and (max-width: 1570px) {
  #__BVID__47__outer_ {
    margin: 10px 0 0 46px !important;
  }
}
@media screen and (max-width: 1200px) {
  #search-row {
    flex-direction: column;
  }
}*/
@media screen and (max-width: 1570px) {
  #search-row {
    flex-direction: column;
    align-content: center;
  }
  #first-col {
    max-width: 94%;
    align-self: center;
  }
  #last-col {
    margin-top: 20px;
  flex: 0 0 100%;
  max-width: 100%;
  }
  div.col {
    text-align: end;
  }
  div.col:first-of-type {
    text-align: start;
  }
}
</style>