<template>
  <div>
    <div id="table-bucket">
      <b-pagination
        class="m-2"
        id="pagination"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="mainTable"
        align="fill"
        pills
        size="l"
        limit="15"
      />
      <div>
        <b-table
          id="mainTable"
          ref="mainTable"
          :tbody-tr-class="rowClass"
          :per-page="perPage"
          :current-page="currentPage"
          :items="tableDataArrayFiltered"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-compare="customCompare"
          @filtered="onFiltered"
          show-empty
          empty-text="Es gibt leider keine Ergebnisse für deine Suche."
          empty-filtered-text="Es gibt leider keine Ergebnisse für deine Suche."
        >
          <template #empty="scope">
            <b-alert show variant="info text-center">{{
              scope.emptyText
            }}</b-alert>
          </template>
          <template #emptyfiltered="scope">
            <b-alert show variant="info text-center">{{
              scope.emptyFilteredText
            }}</b-alert>
          </template>
          <template #cell(_showDetails)="row">
            <b-icon
              icon="arrow-bar-up"
              scale="1.25"
              @click="row.toggleDetails"
              v-if="row.item._showDetails && row.item.orders"
              class="clickable"
            ></b-icon>
            <b-icon
              icon="arrow-bar-down"
              scale="1.25"
              @click="row.toggleDetails"
              v-if="!row.item._showDetails && row.item.orders"
              class="clickable"
            ></b-icon>
          </template>

          <template #cell(ced)="row">
            <p>{{ row.item.ced }}</p>
          </template>

          <template #cell(ccd)="row">
            <p>{{ row.item.ccd }}</p>
          </template>

          <template #cell(qtyTD)="row">
            <strong>{{ row.item.qtyTD }}</strong>
          </template>

          <template #cell(qtySumm)="row">
            <strong>{{ row.item.qtySumm }}</strong>
          </template>

          <template #cell(qtyM)="row">
            <div v-if="!row.item.editQtyM">
              <label
                @click="
                  row.item.editQtyM = true;
                  $refs.mainTable.refresh();
                "
                class="clickable"
              >
                {{ row.item.qtyM }}
              </label>
            </div>
            <input
              v-if="row.item.editQtyM"
              :ref="'qty' + row.item.key"
              :value="row.item.qtyM"
              v-on:blur="
                row.item.editQtyM = !row.item.editQtyM;
                updateQtyM($event.target.value, row.item.key);
                $refs.mainTable.refresh();
              "
              @keyup.enter="
                row.item.editQtyM = !row.item.editQtyM;
                updateQtyM($event.target.value, row.item.key);
                $refs.mainTable.refresh();
              "
              class="w-30"
              type="number"
            />
          </template>

          <template #cell(comment)="row">
            <div v-if="!row.item.editComment && row.item.comment.trim()">
              <label
                @click="
                  row.item.editComment = true;
                  $refs.mainTable.refresh();
                "
                class="clickable"
              >
                {{ row.item.comment }}
              </label>
            </div>
            <div v-if="!row.item.editComment && !row.item.comment.trim()">
              <font-awesome-icon
                :icon="['fa', 'edit']"
                @click="
                  row.item.editComment = true;
                  $refs.mainTable.refresh();
                "
                class="clickable"
              />
            </div>
            <input
              v-if="row.item.editComment"
              :ref="'comment' + row.item.key"
              :value="row.item.comment"
              v-on:blur="
                row.item.editComment = !row.item.editComment;
                updateComment($event.target.value, row.item.key);
                $refs.mainTable.refresh();
              "
              @keyup.enter="
                row.item.editComment = !row.item.editComment;
                updateComment($event.target.value, row.item.key);
                $refs.mainTable.refresh();
              "
              class="w-30"
            />
          </template>

          <template #row-details="row">
            <b-card>
              <OrdersTable
                :orderTableDataArray="
                  row.item ? Object.values(row.item.orders) : []
                "
              />
            </b-card>
          </template>
        </b-table>
      </div>
      <b-pagination
        class="m-2"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="mainTable"
        align="fill"
        pills
        size="l"
        limit="15"
      />
    </div>
  </div>
</template>
<script>
import OrdersTable from "./OrdersTable.vue";
import moment from "moment-timezone";
import { ref, update} from "firebase/database"

export default {
  name: "Table",
  components: {
    OrdersTable,
  },
  props: {
    tableDataArray: Array,
    filter: String,
    filterOn: Array,
    filterOnStatus: String,
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.state === "offen") return;
      if (item.state === "teil") return "table-warning";
      if (item.state === "voll") return "table-success";
      if (item.state === "error") return "table-danger";
    },
    updateComment(value, key) {
      this.changeState(key, "refreshing-comment");
        update(ref(this.$rdb , "ARMData/" + key), {
          comment: value,
        })
        .then(() => {
          this.changeState(key, "success-comment");
        })
        .catch((error) => {
          this.changeState(key, "error-comment");
          console.log("error", error);
        });
      return null;
    },
    updateQtyM(value, key) {
      if (
        value === "" ||
        isNaN(value) ||
        value === null ||
        value === undefined
      ) {
        value = 0;
      }
      this.changeState(key, "refreshing-qtyM");
        update(ref(this.$rdb, "ARMData/" + key), {
          qtyM: value,
        })
        .then(() => {
          console.log("done");
        })
        .catch((error) => {
          this.changeState(key, "error-qtyM");
          console.log("error", error);
        });
      return null;
    },
    changeState(key, state) {
      let index = this.tableDataArray.findIndex(
        (element) => element.key === key
      );
      this.tableDataArray[index].saveState = state;
      this.$refs.mainTable.refresh();
    },
    onFiltered(filteredItems) {
      this.fRows = filteredItems.length;
      this.currentPage = 1;
    },
    customCompare(itemA, itemB, key) {
      if (key !== "ccd" && key !== "ced") {
        return false;
      } else {
        let a = itemA[key];
        let b = itemB[key];
        a = moment(a, "DD.MM.YYYY").format("X");
        b = moment(b, "DD.MM.YYYY").format("X");
        return a - b;
      }
    },
  },
  computed: {
    rows() {
      return this.tableDataArrayFiltered.length;
    },
    tableDataArrayFiltered() {
      let filteredData = [];
      if (this.filterOnStatus !== "" && this.filterOnStatus !== undefined) {
        filteredData = this.tableDataArray.filter((obj) => {
          return obj.state == this.filterOnStatus;
        });
      } else {
        filteredData = this.tableDataArray;
      }

      if (
        this.filter !== "" &&
        this.filter !== undefined &&
        this.filter !== null
      ) {
        return filteredData.filter((obj) => {
          let check = false;
          let each = Object.keys(obj);
          if (this.filterOn[0] !== "") {
            String(obj[this.filterOn[0]])
              .toLowerCase()
              .includes(String(this.filter.toLowerCase())) && (check = true);
          } else {
            for (var i = 0; i < each.length; i++) {
              String(obj[each[i]])
                .toLowerCase()
                .includes(String(this.filter.toLowerCase())) && (check = true);
            }
            if (obj.orders !== undefined && obj.orders[0] !== "") {
              let orderEach = Object.keys(obj.orders);
              for (var l = 0; l < orderEach.length; l++) {
                let orderEachValue = Object.keys(obj.orders[orderEach[l]]);
                for (var o = 0; o < orderEachValue.length; o++) {
                  String(obj.orders[orderEach[l]][orderEachValue[o]])
                    .toLowerCase()
                    .includes(String(this.filter.toLowerCase())) &&
                    (check = true);
                }
              }
            }
          }
          return check;
        });
      } else {
        return filteredData;
      }
    },
  },
  updated() {
    if (this.filter === "") {
      this.fRows = null;
    }
  },
  data() {
    return {
      fRows: null,
      perPage: 20,
      currentPage: 1,
      sortBy: "clNr",
      fields: [
        {
          key: "_showDetails",
          label: "Ord.",
        },
        {
          key: "vip",
          label: "VIP Nummer",
          sortable: true,
        },
        {
          key: "customer",
          label: "Endkunde",
          sortable: true,
        },
        {
          key: "ced",
          label: "CED",
          sortable: true,
        },
        {
          key: "ccd",
          label: "CCD",
          sortable: true,
        },
        {
          key: "reseller",
          label: "Reseller",
          sortable: true,
        },
        {
          key: "term",
          label: "Term",
          sortable: true,
        },
        {
          key: "lvl",
          label: "Level",
          sortable: true,
        },
        {
          key: "productPlan",
          label: "Produkt Plan",
          sortable: true,
        },
        {
          key: "partNumber",
          label: "Part Nummer",
          sortable: true,
        },
        {
          key: "segment",
          label: "Segment",
          sortable: true,
        },
        {
          key: "qtyTD",
          label: "Menge TD",
          sortable: true,
        },
        {
          key: "qtyA",
          label: "Menge A",
          sortable: true,
        },
        {
          key: "qtyM",
          label: "Menge M",
          sortable: true,
        },
        {
          key: "qtySumm",
          label: "Menge Σ",
          sortable: true,
        },
        {
          key: "state",
          label: "Status",
          sortable: true,
        },
        {
          key: "comment",
          label: "Bemerkung",
          sortable: true,
        },
      ],
    };
  },
};
</script>

<style scoped>
table.no-styles tr {
  background-color: transparent !important;
  border-bottom: 1px solid #dee2e6;
}
table.no-styles tr:last-of-type {
  border-bottom: none;
}
table.no-styles td {
  border: 0;
  padding: 0;
}
table thead tr th {
  vertical-align: middle !important;
  border-top: none !important;
}
.b-table-sticky-header {
  margin-bottom: 0 !important;
  max-height: 100% !important;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
}
.card-body {
  padding: 0 !important;
}
#table-bucket {
  width: 100%;
  overflow-x: auto;
}
#pagination {
  min-width: 1918px;
}
</style>
