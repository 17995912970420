<template>
  <div>
    <nav v-if="this.$user.currentUser" class="navbar navbar-expand-lg navbar-dark bg-primary mb-4">
      <div class="container">
      <router-link class="navbar-brand d-flex" to="/">
        <Logo :fill="'#fff'" :height="'33px'" :width="'33px'" class="mb-0 mr-2 d-flex align-items-center"></Logo>
        Adobe Renewal Manager
      </router-link>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto ml-3">
            <template v-if="this.$user.currentUser">
              <b-nav-item>
                <router-link to="/home">Startseite</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/upload">Upload</router-link>
              </b-nav-item>
            </template>
          </ul>
        </div>  
        <div>
          <template v-if="this.$user.currentUser">
            <a class="nav-link" @click.prevent="signOut"><font-awesome-icon :icon="['fas', 'sign-out']" /></a>
          </template>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import Logo from '../assets/Logo'

export default {
  name: 'Navbar',
  components: {
    Logo,
  },
  methods: {
    signOut() {
      this.$user
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "login"
          });
        });
    }
  }
};
</script>
<style>
.navbar { 
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
a.nav-link,
li a {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.788) !important;
}
a.nav-link:hover,
li a:hover, li a:active {
  color: rgb(255, 255, 255) !important;
} 
</style>