import Vue from 'vue';
import Login from '../components/Login';
import Home from '../components/Home';
import Upload from '../components/Upload';
import Router from 'vue-router';
import NotAllowed from "../components/NotAllowed";

//import firebase from 'firebase/compat/app'
//import "firebase/compat/auth";

import { getAuth } from "firebase/auth";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { 
            path: '*',
            redirect: '/login' 
          },
          { 
            path: '/', 
            redirect: '/login' 
          },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/token/:token',
            name: 'token',
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/home',
            name: 'home',
            component: Home,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/notallowed",
            component: NotAllowed,
            name: "NotAllowed",
            meta: {
              requiresAuth: true
            }
          },
        {
            path: '/upload',
            name: 'upload',
            component: Upload,
            meta: {
                requiresAuth: true
            }
        },
]
});

router.beforeEach(async (to, from, next) => {
    const currentUser = await getAuth().currentUser
    const getIdTokenResult = currentUser ? await currentUser.getIdTokenResult(): false
    const isUser = getIdTokenResult ? getIdTokenResult.claims.user : false
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    if ((to.name === 'login' || to.name === 'NotAllowed') && currentUser && isUser) next({ name: 'home' })
    else if (to.name !== 'NotAllowed' && !isUser && currentUser && requiresAuth) next({ name: 'NotAllowed' })
    else if (requiresAuth && currentUser) next()
    else if (requiresAuth && !currentUser) next({ name: 'login' })
    else next()
})

export default router