<template v-if="this.user">
    <div>
      <SearchAndFilter @searchInput="search" @selectedSearchFilter="selectedFilter" @selectedSearchStatus="selectedStatus" :data="data ? data : {}" @ccdFrom="changeCcdFrom" @ccdTo="changeCcdTo"/>
      <MainTable :tableDataArray="data ? objToArr(data.ARMData) : []" :filter="searchInput" :filterOn="selectInput" :filterOnStatus="selectStatus"/>
    </div>
</template>

<script>

import MainTable from './MainTable.vue';
import SearchAndFilter from './SearchAndFilter.vue';
import moment from 'moment-timezone';
import { ref, onValue, query, orderByChild, startAt, endAt, off } from "firebase/database";

export default {
  name: 'Home',
  components: {
    MainTable,
    SearchAndFilter,
  },
  data(){
    return{
      data: {
        ARMData: Object,
        ccd: null
      },
      searchInput: null,
      selectInput: null,
      selectStatus: null,
      ccdFrom: 0,
      ccdTo: 0,
      dbConn: undefined
    }
  },
  methods: {
    readCCDFromDb(){
      return new Promise((resolve) => {
          const dataRef = ref(this.$rdb, "ccd")
          onValue(dataRef, (snapshot) => {
            resolve(snapshot.val())
          })
      })
    },
    readARMDataFromDb(){
      this.dbConn = query(ref(this.$rdb, 'ARMData'), orderByChild("ccd"), startAt(this.ccdFrom), endAt(this.ccdTo))
      console.log("check", this.dbConn)
      onValue(this.dbConn, snapshot => {
        let rawData = snapshot.val()
        let key = Object.keys(rawData)
        let finalData = {}
        for (var i = 0; i < key.length; i++) {
          finalData[key[i]]=rawData[key[i]]
          finalData[key[i]].ccd = moment.unix(rawData[key[i]].ccd).format('DD.MM.YYYY')
          finalData[key[i]].ced = moment.unix(rawData[key[i]].ced).format('DD.MM.YYYY')
        }
        this.data.ARMData = finalData
      })
    },
    search(value) {
      this.searchInput = value
    },
    selectedFilter(value) {
      this.selectInput = [value]
    },
    selectedStatus(value) {
      this.selectStatus = [value][0]
    },
    changeCcdFrom(value) {
      const newFrom = moment(value).tz("Europe/Berlin").format('X')
      if (this.ccdFrom !== newFrom){
        this.ccdFrom = newFrom
        off(this.dbConn)
        this.ARMData = {}
        this.readARMDataFromDb()
      }
    },
    changeCcdTo(value) {
      const newTo = moment(value).tz("Europe/Berlin").format('X')
      if (this.ccdTo !== newTo){
        this.ccdTo = newTo
        off(this.dbConn)
        this.ARMData = {}
        this.readARMDataFromDb()
      }
    },
    objToArr(object){
      var temp = []
      if(object !== {} && object !== undefined && object !== null){
        for (const [key, value] of Object.entries(object)) {
          value.key = key
          temp.push(value)
        }
      }
      return temp
    }
  },
  async mounted(){
   this.data.ccd = await this.readCCDFromDb()
   this.ccdFrom = this.data.ccd.from
   this.ccdTo = this.data.ccd.to
   this.data.ARMData = null
  this.readARMDataFromDb()
  }
}
</script>

<style>
.clickable {
  cursor: pointer;
}
</style>
