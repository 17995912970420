<template>
  <div style="margin: 0;" id="app">
    <navbar></navbar>
      <router-view></router-view>
  </div>
</template>
<script>
import navbar from "./components/Navbar";


export default {
  components: {
    navbar
  }
};
</script>

<style>
span, th, td {
  color: #5c5c5c !important;
}
</style>