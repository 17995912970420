import Vue from "vue";
import App from "./App.vue";
import router from "./routes";

// Font Awesome
import "./fontawesome.js";

// Bootstrap
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@comlinedev/bootstrap4/dist/css/bootstrap.css";

// Firebase
import { initializeApp, getApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const fireApp = initializeApp({
  apiKey: "AIzaSyBIFs7EhcigSZ3EPfJDRgbaKIjXh8SKOUc",
  authDomain: "clgmbh-adobe-renewal-manager.firebaseapp.com",
  databaseURL: "https://clgmbh-adobe-renewal-manager-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "clgmbh-adobe-renewal-manager",
  storageBucket: "clgmbh-adobe-renewal-manager.appspot.com",
  messagingSenderId: "1007892560049",
  appId: "1:1007892560049:web:4a9248546d4dd0e3960a90",
});

const auth = getAuth();
const functions = getFunctions(getApp(), "europe-west3");
const db = getDatabase();

if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099/");
  connectFunctionsEmulator(functions, "localhost", 5005);
  connectDatabaseEmulator(db, "localhost", 9000);
}

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

getAuth(fireApp).onAuthStateChanged(() => {
  new Vue({
    render: (h) => h(App),
    router,
    beforeMount() {
      Vue.prototype.$user = auth;
      Vue.prototype.$rdb = db;
      Vue.prototype.$func = functions;
    },
  }).$mount("#app");
});
