<template>
  <b-table 
  class="table"
    striped 
    :items="orderTableDataArray" 
    :fields="fields"
    :sort-by.sync="sortBy">
    <template #table-colgroup="scope">
      <col
        v-for="field in scope.fields"
        :key="field.key"
        :style="{ width: '20%' }"
      >
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'Table',
  props: {
    orderTableDataArray: Array
  },
  data(){
    return{
      sortBy: '',
      fields: [
        {
          key: 'orderNo',
          label: 'Auftrag Nummer',
          sortable: true,
        },
        {
          key: 'orderDate',
          label: 'Auftrag Datum',
          sortable: true,
        },
        {
          key: 'reseller',
          label: 'Kunde',
          sortable: true,
        },
        {
          key: 'customerNo',
          label: 'Kundennummer',
          sortable: true,
        },
        {
          key: 'qty',
          label: 'Menge',
          sortable: true,
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
